import { ApiConstData } from "./../../../../../consts/ApiConstData";
import { TestServiceService } from "./../../../../services/test_service/test-service.service";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { FormService } from "../../../../services/form/form.service";
import { GlobalFunctions } from "../../../../../consts/global-functions";
import { ActivatedRoute, Router } from "@angular/router";
import { Configurations } from "../../../../../consts/configurations";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { MenuService } from "../../../../services/menu/menu.service";
import { MediaService } from "../../../../services/media/media.service";

@Component({
  selector: "nav_pill_edit__002_006",
  templateUrl: "./nav_pill_edit__002_006.component.html",
  styleUrls: ["./nav_pill_edit__002_006.component.scss"],
})
export class Nav_pill_edit__002_006 implements OnInit {
  fileNameAppendedArr = [];
  appendedFieldName;
  appendFieldFiels = [];



  dropdownList2 = [];
  selectedItems2 = [];
  dropdownSettings2 = {};



  activeTab;
  documents = [];
  fileNameArr = [];
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: "100px",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [["bold", "italic"], ["fontSize"]],
  };
  addItemForm: FormGroup;
  addForm: FormGroup;
  testForm: FormGroup;
  image: string;
  maxImageSize: number = 1024;
  imageName: string;
  imageSize: number;
  fields: Array<any> = [];
  keyValueList: FormArray;
  videoLinksList: FormArray;
  extraFields: Array<any> = [];
  extraFieldGp: Array<any> = [];
  staticApi: string;
  moduleID: number;
  refMainImg: string = "";
  datawithNewFormat = {};
  images = [];
  dropdownSettings;
  mainImg;
  loading: boolean = false;
  loadingConfig: any;
  formTaleData: any;
  selectedItems = {};
  formTabs;
  lang_form: FormGroup;
  defaultLang;
  languages = [];
  tabList = [];
  langObject = {};
  apiLink: string;
  // apiParameters: HttpHeaders;
  languageFields: Array<Object> = [];
  activeLanguageFields: Array<Object> = [];
  taxonomyTermsValue;
  itemID: number;
  formStructure;

  imgExt = ".gif, .jpeg, .jpg, .png";
  fileExt = ".csv, .docx, .xlsx";
  imagecheck;
  mainFile;
  children;
  componentsList;

  fileFieldName = {name:'',type:''};
  fileBase46;
  objFile = {};
  fileCheck;
  

  apiLinkForSubmit: string;
  apiParameters;
  test_options = [
    { value: 18, name: "Admin rule" },
    { value: 19, name: "Shopping cart rule" },
    { value: 20, name: "Business rule" },
    { value: 21, name: "Marketing rule" },
    
  ];
  //(Testing)
  selectedItems1 = [
    { value: 19, name: "Shopping cart rule" },
    { value: 20, name: "Business rule" },
  ];

  constructor(
    public formBuilder: FormBuilder,
    private formService: FormService,
    private GlobalFunctions: GlobalFunctions,
    private activatedRoute: ActivatedRoute,
    public config: Configurations,
    private httpClient: HttpClient,
    private apiConstData: ApiConstData,
    private testService: TestServiceService,
    private router: Router,
    private menuService: MenuService,
    private _mediaService: MediaService,
    private httpCLient: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.activeTab = JSON.parse(localStorage.getItem("activeTab"));

    this.testForm = formBuilder.group({
      testSelectMulti: [""],
    });
    this.lang_form = formBuilder.group({
      lang: [""],
    });

    /*Inside the add form 2 small forms (2 form arrays) filled this form array by function that function create control form*/
    this.addForm = formBuilder.group({
      taxonomy_terms: formBuilder.array([this.createKeyValueFields()]),
      videos: formBuilder.array([this.createVideoFields()]),
    });
  }
  ngDoCheck() {
    $(".languages_tab > ul > .nav-item").addClass("display-block");
  }
  ngAfterViewInit() {
    console.log("nav-item =>", $(".nav-item"));
    if (this.tabList.length == 2) {
      $(".nav-item").addClass("width-nav-bill-2");
    }
    if (this.tabList.length == 1) {
      $(".nav-item").addClass("width-nav-bill-1");
    }
    if (this.tabList.length == 3) {
      $(".nav-item").addClass("width-nav-bill-3");
    }
    if (this.tabList.length == 4) {
      $(".nav-item").addClass("width-nav-bill-4");
    }
    if (this.tabList.length == 1) {
      $(".nav-pills").first().addClass("display--none");
    }
    $(".languages_tab > ul > .nav-item").addClass("display-block");
  }
  ngOnInit() {


    this.dropdownList2 = [
      {value: 30, name: "صندوق العناية بالقرآن الكريم"},
      { value: 1, name: 'Mumbai' },
      { value: 2, name: 'Bangaluru' },
      { value: 3, name: 'Pune' },
      { value: 4, name: 'Navsari' },
      { value: 5, name: 'New Delhi' }
    ];
   
    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'value',
      textField: 'name',
      selectAllText: 'تحديد الكل',
      unSelectAllText: 'الغاء التحديد',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText:'بحث'
    };

    console.log("moduleID test=> ", this.moduleID);
    this.getFields(this.moduleID);

    // this.addForm.controls[element.name].setValue(element.value);

    this.testForm.controls["testSelectMulti"].setValue(this.selectedItems1);
    console.log(
      "formStructure form Tabs_basic_edit__002_003 => ",
      this.formStructure
    );
    /*Loading Configurations*/
    this.loadingConfig = this.config.loadingConfig;
 

    // this.getFields(41)
  }
  testSubmit(value) {
    console.log("values of testing multi select= > ", value);
  }
  /*this function creats the controls of videos el mafrod el validators ana elly a7otha b2edy heana  f el case d*/
  createVideoFields(): FormGroup {
    return this.formBuilder.group({
      name: [""],
      link: [""],
    });
  }

  /*this functio creats the controls of extra fields el mafrod a7ot el validtars b2edy hena*/
  createKeyValueFields(): FormGroup {
    return this.formBuilder.group({
      key: [""],
      value: [""],
    });
  }

  /**this function push the created control of video in list in form array of videos control related to the add form */
  addVideoLinkField() {
    this.videoLinksList.push(this.createVideoFields());
  }

  /********Appending Control********/
  appendingControl(fields): FormGroup {
    let append_fields = {};
    fields.forEach((element) => {
      if (element.value != null) {
        console.log("elementvalue=> ", element.value);
        return (append_fields[element.name] = new FormControl(element.value));
      } else {
        return (append_fields[element.name] = new FormControl(""));
      }
    });

    return new FormGroup(append_fields);
  }

  appendFields(form_submittion_name, fields) {
    console.log(form_submittion_name);
    console.log(fields);
    let appendFields = this.addForm.get(form_submittion_name) as FormArray;
    appendFields.push(this.appendingControl(fields));
  }

  removeFields(form_submittion_name, index) {
    let appendFields = this.addForm.get(form_submittion_name) as FormArray;
    appendFields.removeAt(index);
  }

  /****************/

  addContact() {
    this.keyValueList.push(this.createKeyValueFields());
  }

  removeContact(index) {
    this.keyValueList.removeAt(index);
  }
  //return all group under Key and value
  get keyValueFormGroup() {
    return this.addForm.get("taxonomy_terms") as FormArray;
  }
  get extraFieldGroup() {
    return this.addForm.get("extra_fields") as FormArray;
  }
  get videoFieldsFormGroup() {
    return this.addForm.get("videos") as FormArray;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validatorsList = [];
      validations.forEach((validator) => {
        // validList.push(valid.validator);
        if (validator.name == "minlength") {
          validatorsList.push(Validators.minLength(validator.validator_value));
        } else if (validator.name == "maxlength") {
          validatorsList.push(Validators.maxLength(validator.validator_value));
        } else {
          validatorsList.push(Validators[`${validator.name}`]);
        }
      });
      return Validators.compose(validatorsList);
    }
    return null;
  }

  assignToLangForm(fields) {
    console.log("the default language =>", this.defaultLang);
    console.log("fields assifn to lagn form=> ", fields);
    const group = this.formBuilder.group({});
    // console.log("languages fields =>>>>?//?/////", fields);

    fields.forEach((field) => {
      if (field.type === "button") return;
      const control = this.formBuilder.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    });

    const lang = this.formBuilder.control("");
    group.addControl("lang", lang);
    this.lang_form = group;
  }

  createExtraFields(extraFields) {
    console.log("da5al el function ", extraFields);
    let group = {};
    extraFields.forEach((element) => {
      console.log(element.name);
      console.log(element.id);

      group[element.id] = new FormControl("");
      this.extraFieldGp.push(element.id);
    });

    console.log("extra field Group", this.extraFieldGp);
    console.log("extrafield group form ", group);
    // this.addForm.get('extra_fields').setValue(group);
    return this.formBuilder.group(group);
  }

  changeLanguage(lang_id) {
    console.log("lang-idDD=> ", lang_id);
    console.log("languages fields all => ", this.languageFields);
    console.log("active lanaguae fields_1 => ", this.activeLanguageFields);
    let lang_object = {};
    this.activeLanguageFields.forEach((element) => {
      console.log("element => ", element["value"]);
      // if(element['value'] != " " || element['value']!=undefined) {
      //   console.log("hya b value")
      //   lang_object[`${element['name']}`] = element['value'];
      // }
      if (element["value"]) {
        lang_object[`${element["name"]}`] = element["value"];
        lang_object = { ...lang_object, ...{ lang: this.defaultLang } };
      } else {
        console.log("check if exist", this.langObject[`${this.defaultLang}`]);
        console.log("elemetggg=> ", element["name"]);
        let langObject = this.langObject[`${this.defaultLang}`];
        //lw el da5al el fields w b3dain mas7 w 3amal change
        if (langObject) {
          delete langObject[`${element["name"]}`];
          console.log("hagar", langObject[`${element["name"]}`]);
          console.log(
            "objectLength=> ",
            Object.keys(this.langObject[`${this.defaultLang}`]).length
          );
          if (Object.keys(this.langObject[`${this.defaultLang}`]).length == 0) {
            console.log(this.langObject);
            delete this.langObject[`${this.defaultLang}`];
          }
          console.log(
            "object length => ",
            Object.keys(this.langObject[`${this.defaultLang}`]).length
          );
        } else {
          return;
        }
      }
    });
    this.submitLangFields(lang_object);
    this.activeLanguageFields = this.languageFields[`${lang_id}`];
    this.defaultLang = lang_id;
    console.log("active lanaguae fields => ", this.activeLanguageFields);
  }

  submitLangFields(langFieldsValue) {
    console.log("lang value => ", langFieldsValue);
    let lang_id = langFieldsValue.lang;
    delete langFieldsValue.lang;
    console.log("lang_id=> ", lang_id);
    if (lang_id == undefined) {
      return;
    }
    this.langObject = { ...this.langObject, ...{ [lang_id]: langFieldsValue } };
    console.log("langObject=> ", this.langObject);
  }

  getFields(moduleID) {
    let group = {};
    let validatorsList: Array<any> = [];
    // this.loading = true;
    // this.formService.createCmsAddForm(48, this.staticApi).subscribe(response => {
    this.formTabs = this.formStructure;
    console.log(" this.formTabs => ", this.formTabs)
    this.tabList = this.formStructure.tab_list;
    this.apiParameters = this.formStructure.parameters;
    console.log("Api Parameters ::::::::::", this.apiParameters)
    // this.apiParameters = {
    //     'application_id':this.formStructure.parameters.application_id,
    //     'id':this.formStructure.parameters.id,
    //     'lang_id':2,
    //     'module_id':this.formStructure.parameters.module_id,
    //     'template_id':this.formStructure.parameters.template_id
    // }
    console.log("Api Parameters afer update ::::::::::", this.apiParameters)

    this.apiLinkForSubmit = this.formStructure.api_form_submit;
    this.tabList.forEach((element) => {
      element.tab_content.groups.forEach((gp) => {
        console.log("grpups=> ", gp);
        let fields = gp.fields;
        console.log("appended fields=> ", gp.fields);
        console.log("gp.appendGroup ", gp.append_group);
        if (gp.languages.length > 0) {
          this.languageFields = gp.fields;
          this.defaultLang = gp.languages[0].value;
          console.log("====================================");
          console.log("language fields =>", this.languageFields);
          console.log("gpFields", gp.fields[`${this.defaultLang}`]);
          console.log("====================================");
          this.activeLanguageFields = gp.fields[`${this.defaultLang}`];
          let fillLangOject = {};
          gp.languages.forEach((element) => {
            this.languageFields[`${element.value}`].forEach((langField) => {
              console.log("field.name=> ", langField.name);
              console.log("field.value=> ", langField.value);
              // fillLangOject[langField.name] = langField.value;
              fillLangOject = {
                ...fillLangOject,
                ...{
                  [langField.name]: langField.value,
                },
              };
            });
            this.langObject = {
              ...this.langObject,
              ...{ [element.value]: fillLangOject },
            };
            console.log("====================================");
            console.log(this.langObject);
            console.log("====================================");
          });
          this.assignToLangForm(gp.fields[`${this.defaultLang}`]);
          return;
        }
        console.log("check before => ", gp.append_group);
        if (gp.append_group == 1) {
          console.log("da5al el append Group");
          console.log("fields appended condition=> ", fields);
          console.log("gp.form_submittion_name => ", gp.form_submittion_name);
          group[gp.form_submittion_name] = this.formBuilder.array([
            this.appendingControl(gp.fields),
          ]);

          console.log(
            "gp.appended_fields.length=> ",
            gp.appended_fields.length
          );
          if (gp.appended_fields.length > 0) {
            console.log("gp.appended_fields.length da5al gwa ");

            gp.appended_fields.forEach((element) => {
              this.appendFields(gp.form_submittion_name, element);
            });

            // gp.appended_fields.forEach(element=>{

            // })
          }
        }

        if (gp.append_group == 0) {
          console.log("not appended");
          console.log("not appended fields => ", fields);

          fields.forEach((element) => {
            console.log("element validation=> ", element.validations);
            // group[element.name] = new FormControl('');
            //**********************-Key & Value-******************** */
            if (element.name == "taxonomy_terms") {
              group[element.name] = this.formBuilder.array([
                this.createKeyValueFields(),
              ]);
            } else if (element.name == "videos") {
              /**************************-Videos-******************* */
              group[element.name] = this.formBuilder.array([
                this.createVideoFields(),
              ]);
            } else if (element.name == "extra_fields") {
              /*************************-Extra Fields-************************* */
              console.log("extra field value", element.value);
              // this.createExtraFields(element.value)
              group[element.name] = this.formBuilder.array([
                this.createExtraFields(element.value),
              ]);
              this.extraFields = element.value;
              console.log(
                "=======",
                this.addForm.get("extra_fields") as FormArray
              );
            } else if (element.name == "data") {
              return;
            } else if(element.name == 'images') {
              console.log("ijijijijijijijijijijijijiji");
              console.log("element=> ",element)
              this.images = element.value;
              console.log("iam here type== images", this.images);
            }
            
            else {
              /****************************************-Normal Fields-*************************** */
              console.log("elements => ", element);
              console.log("validation list  => ", element.validations);

              /**looping over the validatons list if there are the validations fr this field */
              if (element.validations.length > 0) {
                element.validations.forEach((validator) => {
                  if (validator.name == "minlength") {
                    validatorsList.push(
                      Validators.minLength(validator.validator_value)
                    );
                  } else if (validator.name == "maxlength") {
                    validatorsList.push(
                      Validators.maxLength(validator.validator_value)
                    );
                  } else if (validator.name == "pattern") {
                    validatorsList.push(
                      Validators.pattern(validator.validator_value)
                    );
                  } else if (validator.name == "required") {
                    validatorsList.push(Validators[`${validator.name}`]);
                  }
                });
              }

              group[element.name] = new FormControl(
                "",
                Validators.compose(validatorsList)
              );
            }
          });

          console.log("current group=> ", gp);
          console.log("group form object => ", group);
          this.addForm = new FormGroup(group);
          this.keyValueList = this.addForm.get("taxonomy_terms") as FormArray;
          this.videoLinksList = this.addForm.get("videos") as FormArray;

          fields.forEach((element) => {
            console.log("element foreach => ", element);
            if (element.name == "extra_fields") {
              let extraFieldsValue = element.value;
              extraFieldsValue.forEach((extraFieldElement) => {
                this.addForm.controls[`extra_fields`][`controls`][0].controls[
                  `${extraFieldElement.id}`
                ].setValue(`${extraFieldElement.value}`);
              });
            }
            //Incase of videos => "Special Case"
            else if (element.name == "videos") {
              let videosDataList = element.value;
              console.log("videotesr=>", element.value);
              videosDataList.forEach((element) => {
                this.videoLinksList.push(this.createVideoFields());
              });
              // console.log("")
              let viedoFormGp = this.addForm.controls[`videos`][`controls`];
              for (let i = 0; i < viedoFormGp.length - 1; i++) {
                viedoFormGp[i].controls[`link`].setValue(
                  `${videosDataList[i].link}`
                );
                viedoFormGp[i].controls[`name`].setValue(
                  `${videosDataList[i].name}`
                );
              }
            }
            //Incase of taxonomy_terms(Key Value) => "special Case"
            else if (element.name == "taxonomy_terms") {
              this.taxonomyTermsValue = element.value;

              this.taxonomyTermsValue.forEach((taxonomyTerm, index) => {
                this.keyValueList.push(this.createKeyValueFields());
              });
              let taxonomy_termsFormGp = this.addForm.controls[
                `taxonomy_terms`
              ][`controls`];

              for (let i = 0; i < taxonomy_termsFormGp.length - 1; i++) {
                taxonomy_termsFormGp[i].controls[`key`].setValue(
                  `${this.taxonomyTermsValue[i].key}`
                );
                taxonomy_termsFormGp[i].controls[`value`].setValue(
                  `${this.taxonomyTermsValue[i].value}`
                );
              }
            }
            else if(element.inputType == 'file') {
              console.log("iam here in inputType file")
                  var extension ;
                  var fileRef;
                  var imgRef;
                  if(element.value == null) {
                    return;
                  }
                  else {
                    extension =  element.value
                    .substring(element.value.lastIndexOf(".") + 1)
                    .toLowerCase();
                    fileRef = this.fileExt.indexOf(extension);
                    imgRef = this.imgExt.indexOf(extension);
                    if(imgRef > 1) {
                      element['ref'] = 'img'
                      console.log("element ref=> ",element)
                    }
                    else {
                      element['ref'] = "file"
                      console.log("element ref=> ",element)
                    }
                  
                    this.addForm.controls[element.name].setValue(element.value);
                  }
            }
            else if(element.name == 'images'){
              console.log("setvalue of images => ")
              return ;
            }
            
            else {
              console.log("element.name=> ", element.name);
              console.log("element.value=> ", element.value);
              console.log("addForm test =>", this.addForm);
              this.addForm.controls[element.name].setValue(element.value);
            }
            console.log("element.name=> ", element.name);
            console.log("element.value=> ", element.value);
          });
        }

        //-----------------------------------put the vale in fields------------------------------
      });
    });

    // })
  }

  /**
   * this function allows you change the format of date
   * @param dateFormControlName :formControlName of date input
   */
  DateFormat(dateFormControlName) {
    let dateValue = this.addForm.get(`${dateFormControlName}`).value;
    const selectesDateTime = new Date(
      Date.parse(dateValue) - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    this.datawithNewFormat[`${dateFormControlName}`] = selectesDateTime;
    console.log(this.datawithNewFormat);
  }

  /**
   * this function extracts the time from all date time format
   * @param timeFormControlName :formControlName of time input
   */
  timeFormat(timeFormControlName) {
    console.log("time Format");
    let timeValue = this.addForm.get(`${timeFormControlName}`).value;
    console.log("time Format", timeValue);

    this.datawithNewFormat[
      `${timeFormControlName}`
    ] = timeValue.toString().slice(16, 21);
    // console.log("NewFormat", timeValue.toString().slice(16,21))
    console.log(this.datawithNewFormat);
  }

  /**
   * this function reads the image file,converts it to base64 and injects the base64 data to datawithNewFormat object
   * @param $event : change Event to get the file from it
   * @param formControlName : the formControlName of input file in this case (Image file);
   *
   *Note (anyone work in this project please combine the two functions (readMainImage and readImage)):D
   */

  readMainImage($event: any, formControlName): void {
    console.log("Mian_file => ", this.mainFile);
    var file: File = $event.target.files[0];

    var extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    this.imagecheck = this.imgExt.indexOf(extension);
    console.log("iamgeCheck => ", this.imagecheck);
    console.log("extension=> ", extension);
    var reader: FileReader = new FileReader();

    reader.onloadend = (e) => {
      this.datawithNewFormat[`${formControlName}`] = reader.result;
      this.mainFile = reader.result;
      $("#uploader-mainImg").addClass("display--none");
      $("#penta-file").val("");
      console.log("main_image => ", reader.result);
    };
    reader.readAsDataURL(file);
  }

  deleteMainImage() {
    this.mainFile = "";
    $("#uploader-mainImg").removeClass("display--none");
  }

  readImage(event, ref, imageFormControlName) {
    this.refMainImg = ref;
    console.log("ref of image => ", ref);
    var files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files) {
        this.imageName = files[i].name;
        this.imageSize = files[i].size;
        console.log("file name => ", files[i].name);
        console.log("file size => ", files[i].size);
        if (ref == "images" || ref == "files") {
          console.log("da5al el not main el ref");
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }
        if (ref == "main") {
          console.log("da5al el main ref");
          this.refMainImg = "main";
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }
      }
    }
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;

    if (this.refMainImg == "main") {
      this.mainImg = "data:image/jpeg;base64," + btoa(binaryString);
    } else if (this.refMainImg == "images") {
      this.image = "data:image/jpeg;base64," + btoa(binaryString);
      if (this.imageSize / 1000 > this.maxImageSize) {
        this.images.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image: this.image,
          class: "red-class",
        });
      } else {
        this.images.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image: this.image,
          class: "default",
        });
      }
    } else if (this.refMainImg == "files") {
      console.log("iam here files");
      this.image = "data:image/jpeg;base64," + btoa(binaryString);
      if (this.imageSize / 1000 > this.maxImageSize) {
        this.documents.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          image:
            "https://icon-library.com/images/file-icon-png/file-icon-png-12.jpg",
          file: this.image,
          class: "red-class",
        });
      } else {
        this.documents.push({
          name: this.imageName,
          size: this.imageSize,
          index: this.images.length,
          file: this.image,
          image:
            "https://icon-library.com/images/file-icon-png/file-icon-png-12.jpg",
          class: "default",
        });
      }
    }
    console.log("image Name => ", this.imageName);

    // this.images.push(this.image);
  }

  closebtn(index, img) {
    console.log("image => ", img);
    console.log("value2");
    console.log("imgID=> ", img);
    let mediaBody = { id: img.id, module_id: this.moduleID };

    this._mediaService.deleteMedia(mediaBody).subscribe(
      (response: any) => {
        console.log("response=> ", response);
        this.images.splice(index, 1);
        $.each(this.images, function (id, value) {
          value.index = id;
        });
      },
      (error) => {
        console.log("error");
      }
    );
  }

  onItemSelect(item: any) {
    console.log(item.value);
  }

  onSelectAll(items: any) {
    console.log(items.value);
  }

  getTableData(event) {
    this.formTaleData = event;
  }

  // clickSelect(event,base,name,target_url){
  //   console.log("event =>",event)
  //     if(base == '') {
  //       return;
  //     }
  //     else {
  //       if(this.selectedItems[`${base}`]) {
  //         let value = this.selectedItems[`${base}`].value;
  //         let object ={}
  //         object[`${base}`] = value;
  //         let body = {...this.apiConstData.getHeaderandBody().apiBody,...object}
  //         console.log("object=> ",object)
  //         this.loading = true;
  //         this.httpClient.post<any>(`${target_url}`,body,{headers:this.apiConstData.getHeaderandBody().apiHeaders})
  //         .subscribe(response => {
  //           this.loading = false;
  //           this.fields.forEach(element=> {
  //             if(element.name == `${name}`) {
  //               element.options = response
  //             }
  //           })

  //         },error => {
  //           this.loading = false;
  //           alert("jgjb")
  //         })
  //       }
  //       console.log("value of base => ",this.selectedItems[`${base}`].value)
  //     }
  // }

  changeSelect(event, name, base, gpIndex, tabIndex) {
    let fieldsOfGp = this.formTabs.tab_list[tabIndex].tab_content.groups[
      gpIndex
    ].fields;

    this.selectedItems[`${name}`] = { value: `${event.target.value}` };
    fieldsOfGp.forEach((element) => {
      console.log("element.base =>", element.base);
      if (element.base == `${name}`) {
        console.log("element /*/*/*/*/*/*/*8/8/8/8/8=> ", element);
        let object = {};
        object[`${name}`] = event.target.value;
        console.log("object selet =>", object);
        let body = {
          ...this.apiConstData.getHeaderandBody().apiBody,
          ...object,
        };
        this.loading = true;
        this.httpClient
          .post<any>(`${element.target_url}`, body, {
            headers: this.apiConstData.getHeaderandBody().apiHeaders,
          })
          .subscribe((response) => {
            console.log("dsjjds", response);
            element.options = response;
            this.loading = false;
          });
      }
    });
    console.log("selected items=> ", this.selectedItems);
  }

  /**
   * this function try to submit the values form to api
   * @param formValue : value of input fields
   */
  submit(formValue) {
    this.loading = true;
    console.log("Date formate => ", formValue);
    formValue[`images`] = this.images;
    formValue[`documents`] = this.documents;
    formValue[`formTableData`] = this.formTaleData;
    let lang_object = {};
    //by5od el active languages fields 3lshan mafesh submit
    this.activeLanguageFields.forEach((element) => {
      console.log("element => ", element["value"]);

      if (element["value"]) {
        lang_object[`${element["name"]}`] = element["value"];
        lang_object = { ...{ lang: this.defaultLang }, ...lang_object };
      }
      else {
        lang_object[`${element["name"]}`] = '';
        lang_object = { ...{ lang: this.defaultLang }, ...lang_object };
      }
    });
    this.submitLangFields(lang_object);
    formValue[`lang_fields`] = this.langObject;
    formValue[`id`] = this.itemID;
    formValue[`lang_id`] = 2;

    formValue = {
      ...formValue,
      ...this.objFile,
      ...this.datawithNewFormat,
      ...this.apiParameters,
    };
    console.log("new form Value", formValue);
    console.log("images => ", this.images);
    console.log("formValue after", formValue);

    // http://penta-test.com/knowledge_base/pentalibrary/public/users/store
    this.httpClient
      .post<any>(`${this.apiLinkForSubmit}`, formValue, {
        headers: this.apiConstData.getHeaderandBody().apiHeaders,
      })
      .subscribe(
        (response) => {
          this.loading = false;
          if(response.status.code == 401){
            localStorage.clear();
            localStorage.clear();
            this.router.navigate(['./login'])
        }
        else{
          console.log("submit response => ", response.status.code);
          console.log("response => ", response);
          this.GlobalFunctions.handelErrorCode(
            response.status.code,
            response.status.validation_errors,
            response.status.message
          );
          if (response.status.code == 200) {
            let navigationLink = this.menuService.getLayoutByID(
              this.activeTab.layout_id
            );
            this.loading = false;

            console.log("navigation link=> ", navigationLink);
            setTimeout(() => {
              this.router.navigate([`${navigationLink}`], {
                queryParams: {
                  children: this.children,
                  moduleID: this.moduleID,
                  componentsList: this.componentsList,
                },
              });
            }, 1000);
            // setTimeout(function(){
            //   this.router.navigate(['/pages/layouts/layout1'],{queryParams:{children:this.children,moduleID:this.moduleID,componentsList:(this.componentsList)}});
            // },1000)
          }
        }
        
        },
        (error) => {
          this.loading = false;
          if (error.status == 0) {
            this.GlobalFunctions.showToastr(
              "danger",
              "Error",
              "No Internet Connection, Please try again",
              this.config.apiResponseMsg.danger.icon
            );
          }
          this.GlobalFunctions.showToastr(
            "danger",
            "Please try again",
            "Error",
            "fas fa-times"
          );
        }
      );
  }

  readFile(event, imageFormControlName, i?) {
    this.fileNameArr[i] = event.target.files[0].name;
    console.log(this.fileNameArr)
    var files:any = event.target.files;
    console.log("trsssss=>",files[0].name)
    $(`${imageFormControlName}_file_name`).append(`${files[0].name}`)

    this.fileFieldName.name = imageFormControlName;
    for (let i = 0; i < files.length; i++) {
      if (files) {
        var extension;
        extension = files[i].name
          .substring(files[i].name.lastIndexOf(".") + 1)
          .toLowerCase();
        this.imagecheck = this.imgExt.indexOf(extension);
        this.fileCheck = this.fileExt.indexOf(extension);
        console.log("imageCheck=> ", this.imagecheck);
        console.log("fileCheck=> ", this.fileCheck);
        if(this.imagecheck > 0) {
          this.fileFieldName.type = "image";
        };
        if(this.fileCheck > 0){
          this.fileFieldName.type = "file";
        }
        // console.log("image Extenstion => ",this.imgExt.indexOf(extension))

        var reader = new FileReader();
        reader.onload = this.handleReaderLoadedFile.bind(this);
        reader.readAsBinaryString(files[i]);
      }
    }
  }
  appendedFiles(event, fieldName, i,formName)
  {
    this.fileNameAppendedArr[i] =  event.target.files[0].name;
      console.log("append event=> ",event);
      console.log("append fieldName=> ",fieldName);
      console.log("append Index=> ",i);
      console.log("formName=>> ",formName);
      this.appendedFieldName = formName;


      let file = event.target.files[0];
      let reader = new FileReader();
      var obj ={}
      // this.addForm.get(`${formName}`).value[i][`${fieldName}`] = "tttttt";

      reader.readAsDataURL(file);
      reader.onload = (e:any)=>{
        this.appendFieldFiels[i] = obj;
        obj[`${fieldName}`] = e.target.result;
        console.log("appendFieldFiels", this.appendFieldFiels)
        // $(`append_file_${i}`).val(`${test}`)
        // console.log(this.addForm.get(`${formName}`).value);
        // return;
      }

  }


  handleReaderLoadedFile(readerEvt) {
    var binaryString = readerEvt.target.result;
    "data:image/jpeg;base64," + btoa(binaryString);
    if(this.fileFieldName.type == "image") {
      var file = "data:image/jpeg;base64," +btoa(binaryString);
    }
    else {
      var file = "data:file;base64," +btoa(binaryString);
    }
    console.log("currentFileField => ", this.fileFieldName);
    console.log("files=> ", file);
    this.fileBase46 = file;
    console.log("before objFile-> ", this.objFile);
    this.objFile[`${this.fileFieldName.name}`] = file;
    console.log("After objFile-> ", this.objFile);
  }

  deleteFile(controlName)
  {
    console.log("delteFile => ",controlName);
    this.addForm.controls[`${controlName}`].setValue('');
    $(`#${controlName}`).empty();
    $(`#${controlName}_input`).removeClass('display--none');
    $(`#${controlName}_input_container`).removeClass('display--none');
   
  }
  downloadFile(fileLink)
  {
    console.log("file Link =?> ", fileLink);
    window.open(`${fileLink}`)
  }

}
